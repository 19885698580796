const routeKey = 'kargo-track'
function saveBaseRoute(route: string) {
    window.localStorage.setItem(routeKey, route)
}

function deleteBaseRoute() {
    window.localStorage.removeItem(routeKey)
}

function getBaseRoute(): string | null {
    return window.localStorage.getItem(routeKey)
}

export {
    saveBaseRoute,
    getBaseRoute,
    deleteBaseRoute
}
