import React from "react";
import {ContractType} from "../../type-def";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faHandshake, faCreditCard} from "@fortawesome/free-solid-svg-icons";
import {EmptyList} from "../../components";
import {useContract} from "../../contexts/contractContext";

export default function ContractList() {

    const {list, loading, store } = useContract();

    if (loading) {
        return <p className='text-center text-muted'>Changement de la liste ...</p>
    }

    return <div>

        <h6 className='text-uppercase'>
            Les contrats de la journées: {list.length}
        </h6>

        {list.length === 0 && <EmptyList url='/contract.png' />}

        <ul className='list-group list-group-flush'>
            {list.map((item: ContractType) => (
                <li className='list-group-item list-group-item-primar' key={item.id}>
                    <ListItem  {...item} />
                </li>
            ))}
        </ul>
    </div>
}

function ListItem(contract: ContractType) {
    return (
        <div>
            <Link  to={`/contracts/${contract.slug}`}>
                <div className='d-flex justify-content-between'>
                    <h6 className='fs-bold p-0 w-100'>{contract.number}</h6>
                    <div className='w-100 d-flex justify-content-end text-muted'>
                        {/*@ts-ignore*/}
                        <FontAwesomeIcon className="fa-fw text-muted" icon={faEdit} />
                    </div>
                </div>

                <div className='text-muted'>
                    #{contract.id} - {contract.departure_address} {contract.departure_zip_code} {contract.departure_city} - {contract.packages.length} colis
                </div>

                <div className='d-flex justify-content-between'>
                    <div className='w-100'>
                        {/*@ts-ignore*/}
                        <FontAwesomeIcon className={`fa-fw text-${contract.signature_date ? 'success' : 'muted'}`} icon={faHandshake} />
                        {contract.price && <span className='text-muted px-2'>{contract.price} €</span>}
                    </div>
                    <div className='w-100 d-flex justify-content-end text-muted'>
                        {contract.created_at}
                    </div>
                </div>
            </Link>
            {contract.signature_date && (
                <Link  to={`/contracts/${contract.slug}/payments`}>
                    <div className='d-flex justify-content-between mt-2 align-items-center'>
                        <span className='px-2 fw-bol'>Ajouter une facture</span>
                        <button className='btn btn-dark'>
                            {/*@ts-ignore*/}
                            <FontAwesomeIcon className='fa-fw' icon={faCreditCard} /> ({contract.payments.length})
                        </button>
                    </div>
                </Link>
            )}
        </div>
    )
}
