import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSms} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {ContractType} from "../../type-def";

type SMSType = {
    link: string
}
export default function SendSMS({ link }: SMSType) {

    if (link === undefined) {
        return null
    }

    return <div className='w-100 d-flex justify-content-end'>
        <a
            href={link}
            target="_blank"
            className='small text-uppercase fw-bold btn btn-lg btn-outline-dark'
            rel="noreferrer">
            {/*@ts-ignore*/}
            <FontAwesomeIcon className="fa-fw" icon={faSms} />
            Envoi SMS
        </a>
    </div>
}
