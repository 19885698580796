import {Link, Outlet} from "react-router-dom"
import * as React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";

type AppLayoutType = {
    children: React.ReactNode,
    goBack?: string
    title?: string
    wrap?: boolean
}

export default function SimpleLayout({goBack, title, wrap, children}: AppLayoutType) {

    return (
        <div className='vh-100 position-relative'>
            {goBack && (
                <div className='py-3 d-flex align-items-center border-bottom mb-4 bg-dark shadow fixed-top text-white'>
                    <Link to={goBack}>
                        {/*@ts-ignore*/}
                        <FontAwesomeIcon className="fa-fw fa-2x text-white" icon={faChevronLeft} />
                    </Link>
                    <span className='px-2 fw-bold text-uppercase'>{title}</span>
                </div>
            )}
            <div style={{paddingTop: 70}} className={`${wrap ? 'container' : ''}`}>
                {children}
                <Outlet />
            </div>
        </div>
    );
}
