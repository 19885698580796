import React, {useEffect, useState} from "react";
import {Alert, Button, Input, Loader} from "../../components";
import axios from "axios";
import {URL_CENTRAL} from "../../api";
import {getBaseRoute, saveBaseRoute} from "../../helpers";

type EndPointFormType = {
    onSave: (url: string) => void
}

export default function EndPointForm({onSave}: EndPointFormType) {
    const [loading, isLoading] = useState<boolean>(false);
    const [success, isSuccess] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const controller = new AbortController();

    useEffect(() => {
        const baseUrl = getBaseRoute();

        if (baseUrl) {
            onSave(baseUrl)
        }

        if (success) {
            window.location.reload();
        }

        return () => controller.abort()
    }, [success])

    const _submit = async (e: any) => {

        e.preventDefault();

        const forms = e.target.elements
        const code = forms.code.value

        if (code) {

            isLoading(true);

            setError('')

            axios.get(URL_CENTRAL + '/tenants/' + code, {})
                .then(response => {
                    const {data, error, message} = response.data

                    console.log(message)

                    if (! error && data.api_url) {

                        saveBaseRoute(data.api_url)
                        //onSave(api_url)

                        isSuccess(true)

                    } else {
                        setError(message)
                    }

                }).catch(error => {
                    console.log('error', error.response.data)
                }).finally(() => isLoading(false))

        }
    }

    if (loading || success) {
        return <Loader />
    }

    return (
        <form onSubmit={_submit}>
            <Alert type='danger' message={error} />
            <h1 className='h4 text-center text-uppercase'>Renseigner votre code entreprise</h1>
            <div className='text-uppercase'>
                <Input type='text' label="Votre code entreprise" uid='code' placeholder='Ex : A001' />
            </div>
            <div className='my-4'>
                <Button disabled={loading} theme='primary' title='VALIDER' />
            </div>
        </form>
    )
}
