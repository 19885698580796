import _labels from "../data/language"
import {_get, _post} from "../api";
import {useNavigate} from "react-router-dom";
import {useState, createContext, useEffect, useContext} from "react";
import {AppContextType, UserPermissionType, UserType} from "../type-def";
import {deleteBaseRoute} from "../helpers";

const AppContext = createContext<AppContextType>({} as AppContextType);

export default function AppProvider({ children }: { children: React.ReactNode }) {

    const [loading, isLoading] = useState<boolean>(false);
    const [logged, isLogged] = useState<boolean>(false);
    const [user, setUser] = useState<any>();
    const [token, setToken] = useState<string>('');
    const [error, setError] = useState<string>('');
    const controller = new AbortController

    const navigate = useNavigate();
    const _tokenKey: string = 'com.diaspo-fret.token'
    const _userKey: string = 'com.diaspo-fret.auth'
    const can = (permission: UserPermissionType) => {
        const enabled = user?.permissions.includes(permission)
        //console.log(permission+'___', enabled)
        return enabled;
    }

    const login = (login: string, password?: string) => {

        isLoading(true)

        return _post('/users/login', {email: login, password}, controller)
            .then(response => {

                const user = response.data.data ?? {}

                if (user && user.token) {

                    setUser(user)

                    isLogged(true)

                    window.localStorage.setItem(_tokenKey, user.token)
                    window.localStorage.setItem(_userKey, JSON.stringify(user))

                } else {
                    setError("Identifiants incorrects")
                }

                console.log('user', user)
            })
            .catch(error => {
                setError("Une erreur est survenue, contacter un administrateur")
            })
            .finally(() => isLoading(false))

    }
    const logout = () => {

        window.localStorage.removeItem(_tokenKey)
        window.localStorage.removeItem(_userKey)

        //deleteBaseRoute()

        isLogged(false)

        setUser(undefined)

        setError('')
    }

    const redirectIfConnected = () => {

        const token: string = window.localStorage.getItem(_tokenKey) as string
        const user: any = JSON.parse(window.localStorage.getItem(_userKey) ?? '{}') as any

        if (token) {
            // Set the user token
            setToken(token)

            // Set User
            setUser(user)

            // Lunch the loader component
            isLogged(true)

            // Redirect when token is empty
            navigate('/', { replace: true })
        }
    }

    useEffect(() => {

        redirectIfConnected()

        return () => controller.abort()

    }, [logged])

    const labels = _labels['local']

    const value = {
        labels,
        login,
        logout,
        user,
        logged,
        loading,
        token,
        error,
        can
    };

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    );
}

export function useApp() {
    return useContext(AppContext);
}
