import React from "react";
import {PaymentType} from "../../type-def";
import {SendSMS} from "./index";
export default function PaymentList({list}: {list: PaymentType[]}) {

    let total = 0
    for (const item of list) {
        total += parseFloat(String(item.amount))
    }

    return <div>

        <h6 className='text-uppercase'>Historiques des facturations ({list.length})</h6>

        {list.length > 0 && (
            <ul className='list-group list-group-flush'>
                <Heading />
                {list.map(item => (
                    <li key={item.id} className='list-group-item'>
                        <ListItem  {...item} />
                    </li>
                ))}
                <Total total={total} />
            </ul>
        )}
    </div>
}


function Heading() {
    return <div className='d-flex justify-content-between text-uppercase small'>
        <div className='w-50'>
            Libellé
        </div>
        <div className='w-100 d-flex justify-content-end'>
            Montant
        </div>
    </div>
}

function Total({total} : {total: number}) {
    return <div className='d-flex justify-content-between text-uppercase fw-bold'>
        <div className='w-50'>
            Total
        </div>
        <div className='w-100 d-flex justify-content-end'>
            {total}
        </div>
    </div>
}

function ListItem(item: PaymentType) {
    return (
        <div className='d-flex'>
            <div className='w-100 d-flex justify-content-start'>
                {item.designation}
            </div>
            <div className='w-100'>
                <SendSMS link={item.sms_link} />
            </div>
            <div className='w-100 d-flex justify-content-end text-right'>
                {item.amount}
            </div>
        </div>
    )
}

