import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPowerOff, faCamera, faCameraRetro} from "@fortawesome/free-solid-svg-icons";

type HeaderType = {
    title?: string;
    setOpenQr: (open: boolean) => void
    openQr: boolean
}

export default function Header({title, setOpenQr, openQr}: HeaderType) {
    return (
        <header className='p-2'>
            <div className='d-flex justify-content-center align-items-center'>
                <button className={`btn btn-${openQr ? 'success' : 'warning'} shadow`} onClick={() => setOpenQr(!openQr)}>
                    {/*@ts-ignore*/}
                    <FontAwesomeIcon className="fa-fw fa-2x" icon={openQr ? faCamera : faCameraRetro} />
                </button>
            </div>
        </header>
    )
}
