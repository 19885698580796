import * as React from "react";
import {GuestLayout} from "../layouts"
import {Alert, Button, Input, Loader} from "../components"
import {useApp} from "../contexts/appContext"
import {useEffect, useState} from "react";
import {EndPointForm} from "../domains/auth";


const signal = new AbortController()

export default function AuthScreen() {

    const [endPoint, setEndPoint] = useState<string | null>(null)
    const {login, loading, error} = useApp()

    const _submit = async (e: any) => {

      e.preventDefault();

      const forms = e.target.elements
      const email = forms.email.value
      const password = forms.password.value

      if(email && password) {
          await login(email, password)
      }
    }

    return (
        <GuestLayout>
            {endPoint === null && <EndPointForm onSave={setEndPoint} />}
            {endPoint !== null && (
                <form className='w-100' onSubmit={_submit}>
                    <h1 className='h4 text-center text-uppercase'>Connectez-vous</h1>
                    <Alert type='danger' message={error} />
                    <Input  label="Login" placeholder='Ex : letanou@live.fr' uid='email' value='noutathierry@yahoo.fr'/>
                    <Input type='password' label="Mot de passe" uid='password' value='admin@123'/>
                    <div className='my-4'>
                        <Button disabled={loading} theme='primary' title='VALIDER' />
                        {loading && <Loader />}
                    </div>
                </form>
            )}
        </GuestLayout>
    );
}
